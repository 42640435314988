import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";

import Login from "./pages/Login";
import Posts from "./pages/Posts";

import "./assets/scss/style.scss";
import Profile from "./pages/Profile";
import AdminData from "./pages/AdminData";
import CreatePost from "./pages/CreatePost";
import FAQ from "./pages/FAQ";
import PasswordReset from "./pages/PasswordReset";
import Auth from "./utils/auth";
import PopularPosts from "./pages/PopularPosts";
import PasswordResetFinal from "./pages/PasswordResetFinal";
import Test from "./pages/Test";
import ToC from "./pages/Terms";
import Announcements from "./pages/Announcements";

function App() {
  const loggedIn = Auth.loggedIn();
  const admin = Auth.isAdmin();
  const [announcementNotifCount, setAnnouncementNotifCount] = useState(0);
  const increaseCount = () => {
    setAnnouncementNotifCount(announcementNotifCount + 1);
  };
  const decreaseCount = () => {
    setAnnouncementNotifCount(announcementNotifCount - 1);
  };
  if (!loggedIn) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/terms" element={<ToC />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/user-password-reset" element={<PasswordResetFinal />} />
        </Routes>
      </Router>
    );
  } else {
    if (admin === true || admin === "true") {
      return (
        <Router>
          <Routes>
            <Route path="/" element={<Posts />} />
            <Route
              path="/announcement"
              element={
                <Announcements
                  increaseCount={increaseCount}
                  decreaseCount={decreaseCount}
                  count={announcementNotifCount}
                />
              }
            />
            <Route path="/popular" element={<PopularPosts />} />
            <Route path="/terms" element={<ToC />} />
            <Route path="/profile" element={<AdminData />} />
            <Route path="/admin/data" element={<AdminData />} />
            <Route path="/add-post" element={<CreatePost />} />
            <Route path="/contact" element={<FAQ />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </Router>
      );
    } else {
      return (
        <Router>
          <Routes>
            <Route path="/" element={<Posts />} />
            <Route
              path="/announcement"
              element={
                <Announcements
                  increaseCount={increaseCount}
                  decreaseCount={decreaseCount}
                  count={announcementNotifCount}
                />
              }
            />
            <Route path="/popular" element={<PopularPosts />} />
            <Route path="/terms" element={<ToC />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/add-post" element={<CreatePost />} />
            <Route path="/contact" element={<FAQ />} />
            <Route path="/password-reset" element={<PasswordReset />} />
          </Routes>
        </Router>
      );
    }
  }
}

export default App;
