import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu" style={{ justifyContent: "right" }}>
      <li className="">
        <Link to="/contact">Contact</Link>
      </li>{" "}
      <li className="">
        <Link to="/terms">Terms Of Service</Link>
      </li>
      <li className="">
        <Link to="/popular"> Popular Posts</Link>
      </li>{" "}
      <li className="">
        <Link to="/announcement">Announcements</Link>
      </li>
      <li className="">
        <Link to="/profile"> My Profile</Link>
      </li>
    </ul>
  );
};

export default Nav;
